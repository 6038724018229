import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm } from 'antd';
import moment from 'moment';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  Loading3QuartersOutlined,
  CommentOutlined,
  LikeOutlined,
  FileImageOutlined,
} from '@ant-design/icons';
import ExportJsonExcel from 'js-export-excel';
import UploadBulkImages from '../components/uploads/uploadBulkImages';

const Postwall = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [modules] = useGlobal('modules');
  let [languagesCms] = useGlobal('languagesCms');
  let path = props.location.pathname.split('/')[1];
  let module;
  let [isSmall] = useGlobal('isSmall');
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = '-pinned,-date';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'comment';
    }
    let restData = await api
      .get(`/rest/posts?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            if (item.active) item.active = <CheckOutlined />;
            else item.active = <CloseOutlined />;

            if (item.pinned) item.pinned = <CheckOutlined />;
            else item.pinned = <CloseOutlined />;

            if (!item.hasOwnProperty('hashtagGroups')) {
              item.hashtagGroups = [];
            }
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/posts/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };
  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/posts/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let downloadExcel = async () => {
    var option = {};
    let dataTable = [];
    let excelData = await api
      .get(`/rest/posts?${qs.stringify({ page: 1, pageSize: 10000 })}`, {})
      .then(({ data }) => {
        return data.result.rows;
      });

    if (excelData) {
      for (let i in excelData) {
        if (excelData) {
          let type = 'comment';
          let media1 = '';
          let media2 = '';
          let media3 = '';
          let media4 = '';
          let media5 = '';
          if (excelData[i].medias.length > 0) {
            type = 'media';
            if (excelData[i].medias[0]) {
              media1 = excelData[i].medias[0].url;
            }
            if (excelData[i].medias[1]) {
              media2 = excelData[i].medias[1].url;
            }
            if (excelData[i].medias[2]) {
              media3 = excelData[i].medias[2].url;
            }
            if (excelData[i].medias[3]) {
              media4 = excelData[i].medias[3].url;
            }
            if (excelData[i].medias[4]) {
              media5 = excelData[i].medias[4].url;
            }
          }

          let obj = {
            attendee: excelData[i].attendee_name,
            type: type,
            comment: excelData[i].comment,
            media_1: media1,
            media_2: media2,
            media_3: media3,
            media_4: media4,
            media_5: media5,
            like_count: excelData[i].likes.length,
            comments_count: excelData[i].commentsData,
            date: moment(excelData[i].date).format('DD-MM-YYYY'),
          };
          dataTable.push(obj);
        }
      }
    }

    let sheetAreas = [
      'attendee',
      'type',
      'comment',
      'media_1',
      'media_2',
      'media_3',
      'media_4',
      'media_5',
      'like_count',
      'comments_count',
      'date',
    ];

    option.fileName = module ? module.name : '';
    option.datas = [
      {
        sheetData: dataTable,
        sheetName: 'sheet',

        sheetFilter: sheetAreas,
        sheetHeader: [
          languagesCms.POST_OWNER,
          languagesCms.TYPE,
          languagesCms.CONTENTS,
          'media_1',
          'media_2',
          'media_3',
          'media_4',
          'media_5',
          languagesCms.LIKE_COUNT,
          languagesCms.COMMENTS_COUNT,
          languagesCms.DATE,
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let columns = [
    {
      title: languagesCms.SHARE,
      dataIndex: 'attendee_name',
      key: 'attendee_name',
      sorter: (a, b) => a.attendee_name - b.attendee_name,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.MEDIA,
      dataIndex: 'medias',
      key: 'medias',
      render: (text, record) => {
        return (
          <div style={{ width: '100px' }}>
            {record.medias.length > 0 && (
              <div>
                {record.medias[0].type === 'image' && (
                  <img
                    src={record.medias[0].url}
                    style={{ width: '100%' }}
                    alt=""
                  />
                )}
                {record.medias[0].type === 'video' && (
                  <img
                    src={record.medias[0].thumb}
                    style={{ width: '100%' }}
                    alt=""
                  />
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: languagesCms.EXPLANATION,
      dataIndex: 'comment',
      key: 'comment',
      sorter: (a, b) => a.comment - b.comment,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ACTIVE,
      dataIndex: 'active',
      key: 'active',
      sorter: (a, b) => a.active - b.active,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.PIN,
      dataIndex: 'pinned',
      key: 'pinned',
      sorter: (a, b) => a.pinned - b.pinned,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.SHARE_DATE,
      dataIndex: 'date',
      key: 'date',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.date - b.date,
      render: (text) => moment(text).format('YYYY-MM-DD HH:mm'),
    },

    {
      title: languagesCms.GROUP,
      dataIndex: 'hashtagGroups',
      key: 'hashtagGroups',
      sorter: (a, b) => a.hashtagGroups - b.hashtagGroups,
      sortDirections: ['descend', 'ascend'],
      render: (hashtagGroups) =>
        (hashtagGroups && hashtagGroups.length ? hashtagGroups.length : 0) >
          0 &&
        (hashtagGroups || []).map((e, i) => (
          <div key={i} style={{ marginBottom: 5 }}>
            {e.name}
          </div>
        )),
    },
    {
      title: 'Action',
      key: 'action',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <div style={{ margin: 'auto' }}>
            <Link to={'/postwall/likes/' + record._id}>
              <Button
                style={{ marginBottom: '5px' }}
                size="small"
                icon={<LikeOutlined />}
              >
                {isSmall ? '' : languagesCms.LIKES}
              </Button>
            </Link>
            <Link to={'/postwall/comments/' + record._id}>
              <Button size="small" icon={<CommentOutlined />}>
                {isSmall ? '' : languagesCms.COMMENTS}
              </Button>
            </Link>
          </div>
          <Link to={'/postwall/edit/' + record._id}>
            <Button icon={<EditOutlined />}>
              {isSmall ? '' : languagesCms.EDIT}
            </Button>
          </Link>
          <Popconfirm
            onConfirm={() => deleteRow(record._id)}
            title={languagesCms.CHECK_DELETE}
            okText={languagesCms.OK_TEXT}
            cancelText={languagesCms.CANCEL_TEXT}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              {isSmall ? '' : languagesCms.DELETE}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/postwall/face-images">
            <Button
              type="light"
              icon={<FileImageOutlined />}
              style={{ marginRight: '5px' }}
              size="large"
            >
              {isSmall ? '' : 'Yüz Tanımlama Resimleri'}
            </Button>
          </Link>
          <UploadBulkImages isSmall={isSmall} />
          <Button
            size="large"
            onClick={downloadExcel}
            style={{ marginRight: '5px' }}
            icon={<DownloadOutlined />}
          >
            {isSmall ? '' : 'Export Excel'}
          </Button>
          {!isSmall && (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="large"
              onClick={deleteSelectedRows}
              style={{ marginRight: '5px' }}
            >
              {languagesCms.DELETE_SELECTED}
            </Button>
          )}
          <Link to="/postwall/add">
            <Button type="light" icon={<PlusOutlined />} size="large">
              {isSmall ? '' : languagesCms.ADD_NEW}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder={languagesCms.SEARCH_SHARING}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default Postwall;
