import React, { useEffect, useState, useMemo, useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Switch, Card, message, InputNumber } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { EventTablesPicker, EventAttendeesPicker,TableReservation,FileInputSingle} from '../components';
import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback, useRef } from "react";
import ReactQuill  from 'react-quill';

const EventTableReservationUserAdd = (props) => {
  const quillRef = useRef(null);

  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;
  let userId = params.userId;
  let tableId = params.tableId;
  let reserveId = params.reserveId;

  let [langCms] = useGlobal("langCms"); 
  let [langCode] = useGlobal("langCode"); 

  let lang 
  if (langCode) {
    lang = langCode;
  }
  else if (langCms && langCms[0].code) {
    lang = langCms[0].code;
  }
  else lang = "TR"


  let [data, setData] = useState(id ? {} : {});
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false)
 
  let [modules] = useGlobal("modules");
  let [languagesCms] = useGlobal("languagesCms"); 
  let path = props.location.pathname.split('/')[1];
  let module
  if(modules !== null){
     [module] = modules.filter(el => el._id === path);
  }

   
  useEffect(() => {
    if (modules && id) {
      setLoading(true)
        api.get("/api/rezervedUser/"+tableId+"/"+userId+"/" + id+"/"+reserveId).then(({ data: { result, result_message } }) => {
          console.log("RESULT                        ",result)
            setData(result);
          setLoading(false)
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.userNumber === null || data.userNumber.length === 0)
      errors.userNumber = languagesCms.ENFORCED

    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);
console.log("DATA ADDD          ",data)
  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {
      if (id){
        if(data){
          let updatedDate={
            reservationId :id,
            userId:data.userId,
            tableId:data.tableId,
            userNumber:Number(data.userNumber)
          }

        api.post("/api/eventTableReservation", updatedDate).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/eventTableReservation/list/' +id)
          } else {
            message.error(result_message.message, 2);
          }
        })
      }
      }
    }
  };

  let imageHandler = () => {
    const input = document.createElement('input');
  
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
  
    input.onchange = async () => {
        const quill = quillRef.current.getEditor();
        const file = input.files[0];
        const formData = new FormData();
  
        formData.append('files_0', file);
        const range = quill.getSelection(true);
        quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loading.gif`);
        quill.setSelection(range.index + 1);
     
        let url = await api.post("/api/upload", formData, { headers: { 'ContenType': 'multipart/form-data'} }).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            return result[0].url;
          }
        });
        quill.deleteText(range.index, 1);
        quill.insertEmbed(range.index, 'image', url);
      };
    }
  
    const formats = ['header','font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link','image', 'color', 'size', 'video', 'align', 'background', 'direction', 'code-block', 'code','script','clean']
    const quillmodules = useMemo(() => ({
        toolbar: {
            container: [
                [{header: '1'}, {header: '2'}, {header: [3, 4, 5, 6]}],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                ['link', 'image', 'video'],
                [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { align: [] },
                    { direction: 'rtl' },
                  ],
                ['clean'],
                ['code-block'],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{ 'color': [] }, { 'background': [] }],
                [{ script: 'sub' }, { script: 'super' }, 'formula']
            ],
            handlers: {
                image: imageHandler
            }
        }
    }), [])

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/eventTableReservation">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
            <Form layout="horizontal" size={"large"} onFinish={save}>

              <Row direction="row">
              <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={"Kullanıcı"} help={errors.location}
                                               validateStatus={errors.location ? 'error' : 'success'}>
                                        <EventAttendeesPicker languagesCms={languagesCms} record={data} setRecord={setData} name="userId" mode="single"/>
                                    </Form.Item>
                                </Col>
              </Row>

              <Row direction="row">

                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={"Masa"} help={errors.location}
                                               validateStatus={errors.location ? 'error' : 'success'}>
                                        <EventTablesPicker languagesCms={languagesCms} record={data} setRecord={setData} reservationId={id} name="tableId" mode="single"/>
                                    </Form.Item>
                                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={"Rezervasyon Yapılan Kullanıcı Sayısı"} required help={errors.userNumber} validateStatus={errors.userNumber ? 'error' : 'success'}>
                    <Input name="userNumber" 
                     onChange={e => setData({ ...data, userNumber: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                  </Form.Item>
                </Col>
              </Row>

            </Form>
          </Card>
      </div>
    </div>
  );
};

export default EventTableReservationUserAdd;