import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm } from 'antd';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  Loading3QuartersOutlined,
  FileExcelOutlined
} from '@ant-design/icons';

const EventTableReservationUserList = (props) => {
    let params = useParams()
    let history = useHistory()
    let id = params.id !== "add" ? params.id : false;
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [unchangedData, setUnchangedData] = useState([])
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [langCms] = useGlobal("langCms");
  let [langCode] = useGlobal("langCode");


  let [modules] = useGlobal('modules');
  let [languagesCms] = useGlobal('languagesCms');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'order';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page };
    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name';
    }
    let restData = await api
      .get('/api/rezervedUsers/'+id)
      .then(({ data }) => {
        console.log("Data   ",data)
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.map((item, key) => {
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
    setUnchangedData(restData)

  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item) => {
    if (id){
      if(data){
        let updatedDate={
          reservationId :id,
          userId:item.userId,
          tableId:item.tableId,
          reserveId:item.reserveId,
        }

      api.post("/api/deleteRezervedUser", updatedDate).then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          window.location.reload();

        }
      })
    }
    }
  };
  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/eventTableReservation/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
    });
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };
  const filter = (e) => {
    const val = e.target.value.toLocaleLowerCase();
    const tempList = Object.assign([], unchangedData);
    if (val === '' || !val) {
       setData(unchangedData)
       return;
    }
    let filteredData = tempList.filter(t => isContainsFilterValue(t, val))
    setData(filteredData)
 }

 const isContainsFilterValue = (t, val) => {
    const isContains2 = t.name == null ? false : t.name.toLocaleLowerCase('tr').indexOf(val) !== -1;
    const isContains3 = t.tableNo == null ? false : t.tableNo.toLocaleLowerCase('tr').indexOf(val) !== -1;
    const isContains0 = !t.lastname?  false : !t.lastname?false:t.lastname.toLowerCase().indexOf(val) !== -1;
    //const isContains1 = !t.lastname ?  false : !t.lastname?false:t.lastname.toLocaleLowerCase('tr').indexOf(val) !== -1;
    // const isContains4 = t.createdAt == null ? false : val.startsWith(moment(t.createdAt).format("DD-MM-YYYY HH:mm"));
    // let isContain5;
    // // let isContain6;
    // if(val.toLowerCase().indexOf('ysc')!=-1){
    //    isContain5=t.controlType == null ? false : t.controlType!==0;
    // }
    // if(val.toLowerCase().indexOf('silah')!=-1){
    //    isContain6=t.controlType == null ? false : t.controlType!==1;

    // }
    return (isContains0 || isContains2 || isContains3);
 }
     
 useEffect(() => {
  get();
}, [])
  let columns = [
    {
        title: "Masa No",
        dataIndex: 'tableNo',
        key: 'tableNo',
        sorter: (a, b) => a.tableNo - b.tableNo,
        sortDirections: ['descend', 'ascend'],
      },
    {
      title: languagesCms.TITLE,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Soyad",
      dataIndex: 'lastname',
      key: 'lastname',
      sorter: (a, b) => a.lastname - b.lastname,
      sortDirections: ['descend', 'ascend'],
    },
    {
        title: "Kota",
        dataIndex: 'quota',
        key: 'quota',
        sorter: (a, b) => a.quota - b.quota,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: "Doluluk",
        dataIndex: 'fullness',
        key: 'fullness',
        sorter: (a, b) => a.fullness - b.fullness,
        sortDirections: ['descend', 'ascend'],
      },
    {
      title: "Rezerve Edilen Kullanıcı Sayısı",
      dataIndex: 'userNumber',
      key: 'userNumber',
      sorter: (a, b) => a.userNumber - b.userNumber,
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Action',
      key: 'action',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">

          <Link to={'/eventTableReservation/detail/tableId/'+record.tableId+'/userId/'+record.userId+'/reservationId/' + id+"/reserveId/"+record.reserveId}>
            <Button icon={<EditOutlined />}>
              {isSmall ? '' : languagesCms.EDIT}
            </Button>
          </Link>
          <Popconfirm
            onConfirm={() => deleteRow(record)}
            title={languagesCms.CHECK_DELETE}
            okText={languagesCms.OK_TEXT}
            cancelText={languagesCms.CANCEL_TEXT}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              {isSmall ? '' : languagesCms.DELETE}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">

           {/* {!isSmall && (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="large"
              onClick={deleteSelectedRows}
              style={{ marginRight: '5px' }}
            >
              {languagesCms.DELETE_SELECTED}
            </Button>
          )} */}
          <Link to={"/eventTableReservation/add/"+id}>
            <Button type="light" icon={<PlusOutlined />} size="large">
              {isSmall ? '' : "Yeni Rezervasyon"}
            </Button>
          </Link> 
        </div>
      </div>

      <div className="table-wrap">
      <Search placeholder="Ara" onChange={filter}/>

        <Table
          dataSource={data}
          columns={columns}
          onChange={handleTableChange}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default EventTableReservationUserList;
