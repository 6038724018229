import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import { Button, Image, Pagination, Popconfirm } from 'antd';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';

import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  LeftOutlined,
} from '@ant-design/icons';

const Faces = (props) => {
  let params = useParams();
  let query = [];
  let comment = params.id !== 'add' ? params.id : false;
  query['postId'] = comment;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [modules] = useGlobal('modules');
  let [languagesCms] = useGlobal('languagesCms');
  const [imageIds, setImageIds] = useState([]);

  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  const onShowSizeChange = (current, pageSize) => {
    get({ current, pageSize: pageSize || 25 });
    console.log(current, pageSize);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 25 };
    }
    let shortString = 'order';

    if (sorter) {
      shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
    }

    let _params = { sort: shortString, ...page, query };

    await api
      .get(`/rest/face-recognition/images?${qs.stringify(_params)}`, {
        _params,
      })
      .then(({ data }) => {
        if (data && data.result && data.result.rows) {
          setTotalCount(data && data.result && data.result.total);
          setData(data && data.result && data.result.rows);
        }
      });
  };

  useEffect(() => {
    if (modules) {
      get();
    }
  }, []);

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Popconfirm
            onConfirm={() => {
              api
                .post('/rest/face-recognition/bulk-delete', { ids: imageIds })
                .then(() => {
                  setImageIds([]);
                  setTimeout(() => get(), 1000);
                })
                .catch((err) => console.log(err));
            }}
            title={languagesCms.CHECK_DELETE}
            okText={languagesCms.OK_TEXT}
            cancelText={languagesCms.CANCEL_TEXT}
          >
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="large"
              style={{ marginRight: '5px' }}
            >
              {languagesCms.DELETE_SELECTED}
            </Button>
          </Popconfirm>
          <Link to="/postwall">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div
        style={{
          marginTop: '20px',
          overflow: 'auto',
          marginLeft: '20px',
          height: '65vh',
          scrollBehavior: 'smooth',
        }}
      >
        {data &&
          data.map((item) => (
            <div style={{ display: 'inline-block', margin: '5px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '5px',
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                  marginBottom: '5px',
                }}
              >
                <div>
                  {item.isProgress ? (
                    <CheckOutlined
                      style={{
                        color: 'green',
                        fontSize: '15px',
                        fontWeight: '900',
                      }}
                    />
                  ) : (
                    <CloseOutlined
                      style={{
                        color: 'red',
                        fontSize: '15px',
                        fontWeight: '900',
                      }}
                    />
                  )}
                </div>
                <div>
                  <input
                    type="checkbox"
                    value={item._id}
                    onChange={() => {
                      if (imageIds.includes(item._id)) {
                        setImageIds(imageIds.filter((id) => id !== item._id));
                      } else {
                        setImageIds([...imageIds, item._id]);
                      }
                    }}
                  />
                  {/* <Checkbox
                    isChecked={imageIds.includes(item._id) ? true : false}
                    onChange={() => {
                      setImageIds([...imageIds, item._id]);
                    }}
                  >
                    {languagesCms.SELECT}
                  </Checkbox> */}
                </div>
              </div>
              <div>
                <Image
                  width={230}
                  height={200}
                  src={item.url || item.thumb || ''}
                />
              </div>
              <div>
                <Popconfirm
                  onConfirm={() =>
                    api
                      .delete(`/rest/face-recognition/images/${item._id}`)
                      .then((res) => {
                        get();
                      })
                  }
                  title={languagesCms.CHECK_DELETE}
                  okText={languagesCms.OK_TEXT}
                  cancelText={languagesCms.CANCEL_TEXT}
                >
                  <Button icon={<DeleteOutlined />} block>
                    {languagesCms.DELETE}
                  </Button>
                </Popconfirm>
              </div>
            </div>
          ))}
      </div>
      <Pagination
        style={{ marginTop: '1%', float: 'right', paddingRight: '5%' }}
        defaultCurrent={1}
        onChange={onShowSizeChange}
        defaultPageSize={25}
        total={totalCount}
      />
    </div>
  );
};

export default Faces;
