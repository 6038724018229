import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Switch,
  DatePicker,
  Card,
  message,
  Alert,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import {ChromePicker} from "react-color";
import rgbHex from "rgb-hex";
import {
  FileInputSingle,
  GroupPicker,
  AttendeePicker,
  SendNotificationInput,
  ModulePicker,
  ItemByNotifModulePicker,
  LangPicker,
  PinNotificationInput,
  SendNowNotificationInput,
} from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';

const EmergencyDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let [moduleChange, setModuleChange] = useState(false);

  let id = params.id !== 'add' ? params.id : false;
  const { TextArea } = Input;
  let [languagesCms] = useGlobal('languagesCms');
  let [langCode] = useGlobal('langCode');
  let [langCms] = useGlobal('langCms');

  let newRecord = {
    lang: langCode ? langCode : langCms[0].code,
    title: '',
    content: '',
    active: true,
    date: new Date(),
    groups: [],
    helpButtonTextColor:"#000000",
    helpButtonBackgroundColor:"#ffffff",
    safeButtonTextColor:"#000000",
    safeButtonBackgroundColor:"#ffffff",
    contentTextColor:"#000000",
    contentBackgroundColor:"#ffffff",
  };

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);

  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/emergency/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.lang === null) errors.lang = languagesCms.ENFORCED;

    if (data.title === null || data.title.length === 0)
      errors.title = languagesCms.ENFORCED;

    if (data.content === null || data.content.length === 0)
      errors.content = languagesCms.ENFORCED;


    if (data.date === null) errors.date = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/emergency/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/emergency');
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        api
          .post('/rest/emergency', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/emergency');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };
  console.log("data.type   ",data.type)
  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/emergency">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.LANG}>
                  <LangPicker
                    langCms={langCms}
                    langCode={langCode}
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="lang"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.GROUP}
                  help={errors.groups}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <GroupPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="groups"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={"Durum"}
                  help={errors.active}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.active ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, active: v })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.SENDED_TIME}
                  required
                  help={errors.date}
                  validateStatus={errors.date ? 'error' : 'success'}
                >
                  <DatePicker
                    defaultValue={() => moment(data.date)}
                    onChange={(v) => setData({ ...data, date: v })}
                    format="DD/MM/YYYY HH:mm"
                    showTime={true}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={"Hemen Gönder"}                  
                >
                  <Switch
                    checked={data.sendNow ? true : false}
                    checkedChildren={languagesCms.ACTIVE}
                    unCheckedChildren={languagesCms.PASSIVE}
                    onChange={(v) => setData({ ...data, sendNow: v })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Görüntülenme Sıklığı'}
                  help={errors.frequency}
                  validateStatus={errors.frequency ? 'error' : 'success'}
                >
                    <Input
                    name="frequency"
                    type="number"
                    value={data.frequency}
                    onChange={(e) =>
                      setData({ ...data, frequency: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>

            </Row>
            <Row>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Görüntülenme Saat Aralığı'}
                  help={errors.displayTimeInterval}
                  validateStatus={errors.displayTimeInterval ? 'error' : 'success'}
                >
                    <Input
                    name="displayTimeInterval"
                    type="number"
                    value={data.displayTimeInterval}
                    onChange={(e) =>
                      setData({ ...data, displayTimeInterval: e.target.value })
                    }
                  />
                 <Alert message={"Saat cinsinden girilmedilir! Default olarak 24 saat kabul edilir."} banner />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.TITLE}
                  required
                  help={errors.title}
                  validateStatus={errors.title ? 'error' : 'success'}
                >
                  <Input
                    name="title"
                    value={data.title}
                    onChange={(e) =>
                      setData({ ...data, title: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col xs={{span: 24}} md={{span: 12}}>
              <Form.Item label="Başlık Yazı Rengi" help={errors.titleTextColor}
                         validateStatus={errors.titleTextColor ? 'error' : 'success'}>
                  <ChromePicker
                      disableAlpha
                      color={data.titleTextColor}
                      onChange={x => setData({
                          ...data,
                          titleTextColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                      })}
                  /> </Form.Item>
          </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.CONTENTS}
                  required
                  help={errors.content}
                  validateStatus={errors.content ? 'error' : 'success'}
                >
                  <TextArea
                    name="content"
                    value={data.content}
                    onChange={(e) =>
                      setData({ ...data, content: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col xs={{span: 24}} md={{span: 12}}>
              <Form.Item label="İçerik Yazı Rengi" help={errors.contentTextColor}
                         validateStatus={errors.contentTextColor ? 'error' : 'success'}>
                  <ChromePicker
                      disableAlpha
                      color={data.contentTextColor}
                      onChange={x => setData({
                          ...data,
                          contentTextColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                      })}
                  /> </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 12}}>
              <Form.Item label="İçerik Arkaplan Rengi" help={errors.contentBackgroundColor}
                         validateStatus={errors.contentBackgroundColor ? 'error' : 'success'}>
                  <ChromePicker
                      disableAlpha
                      color={data.contentBackgroundColor}
                      onChange={x => setData({
                          ...data,
                          contentBackgroundColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                      })}
                  /> </Form.Item>
          </Col>
            </Row>


            <Row direction="row">
              <Col span={12}>
                <Form.Item
                  label={"Yardım Butonu İkon"}
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <FileInputSingle
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="helpButtonIcon"
                  />

                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={"Güvendeyim Butonu İkon"}
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <FileInputSingle
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="safeButtonIcon"
                  />

                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col xs={{span: 24}} md={{span: 8}}>
                                        <Form.Item label="Yardım Butonu text" help={errors.helpButtonText } required
                                                   validateStatus={errors.helpButtonText ? 'error' : 'success'}>
                                            <Input name="helpButtonText" value={data.helpButtonText}
                                                   onChange={e => setData({...data, helpButtonText: e.target.value})}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{span: 24}} md={{span: 8}}>
                                        <Form.Item label="Yardım Buton text Color" help={errors.helpButtonTextColor}
                                                   validateStatus={errors.helpButtonTextColor ? 'error' : 'success'}>
                                            <ChromePicker
                                                disableAlpha
                                                color={data.helpButtonTextColor}
                                                onChange={x => setData({
                                                    ...data,
                                                    helpButtonTextColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                                                })}
                                            /> </Form.Item>
                                    </Col>
                                    <Col xs={{span: 24}} md={{span: 8}}>
                                        <Form.Item label="Yardım Butonu Background Color" help={errors.helpButtonBackgroundColor}
                                                   validateStatus={errors.helpButtonBackgroundColor ? 'error' : 'success'}>
                                            <ChromePicker
                                                disableAlpha
                                                color={data.helpButtonBackgroundColor}
                                                onChange={x => setData({
                                                    ...data,
                                                    helpButtonBackgroundColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                                                })}
                                            />
                                        </Form.Item>
                                    </Col>
            </Row>
            <Row>

            <Col xs={{span: 24}} md={{span: 8}}>
                                        <Form.Item label="Güvendeyim Butonu text" help={errors.safeButtonText } required
                                                   validateStatus={errors.safeButtonText ? 'error' : 'success'}>
                                            <Input name="safeButtonText" value={data.safeButtonText}
                                                   onChange={e => setData({...data, safeButtonText: e.target.value})}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{span: 24}} md={{span: 8}}>
                                        <Form.Item label="Güvendeyim Buton text Color" help={errors.safeButtonTextColor}
                                                   validateStatus={errors.safeButtonTextColor ? 'error' : 'success'}>
                                            <ChromePicker
                                                disableAlpha
                                                color={data.safeButtonTextColor}
                                                onChange={x => setData({
                                                    ...data,
                                                    safeButtonTextColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                                                })}
                                            /> </Form.Item>
                                    </Col>
                                    <Col xs={{span: 24}} md={{span: 8}}>
                                        <Form.Item label="Güvendeyim Butonu Background Color" help={errors.safeButtonBackgroundColor}
                                                   validateStatus={errors.safeButtonBackgroundColor ? 'error' : 'success'}>
                                            <ChromePicker
                                                disableAlpha
                                                color={data.safeButtonBackgroundColor}
                                                onChange={x => setData({
                                                    ...data,
                                                    safeButtonBackgroundColor: ("#" + rgbHex(x.rgb.r, x.rgb.g, x.rgb.b))
                                                })}
                                            />
                                        </Form.Item>
                                    </Col>
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default EmergencyDetail;
