import React, { useState, useMemo } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';
import moment from 'moment';

export default (props) => {
  let { record, setRecord, name, mode, url,placeholder, filterName, filterValue } = props;
  let [cities, setCities] = useState([]);
  let _params = { sort: '-createdAt', page: 1, pageSize: 10000};
  let languagesCms = props.languagesCms;


  useMemo(() => {
    api.get(`/rest/${url}?${qs.stringify(_params)}`).then(({ data }) => {
      if (data && data.result && data.result.rows.length > 0) {
        let dbCities = data.result.rows.sort((a, b) =>
          a.from_date.localeCompare(b.from_date),
        );
        if(filterName && filterValue!="ALL") {
          dbCities = dbCities.filter(x => x[filterName] == filterValue)
        }
        setCities(dbCities);
      }
    });
  }, []);

  let setRecordArr = (v) => {
    console.log("v",v);
    let [result] = cities.filter(x => x._id === v);
    console.log("result",result);
    // sort by alphabetically
    setRecord({...record, [name]: result})
  }
    return (
      <Select
        value={record[name]._id || []}
        showSearch={true}
        mode={mode}
        placeholder={placeholder}
        onChange={(v) => setRecordArr(v)}
        filterOption={(input, option) =>
          option.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >=
          0 ||
        option.props.children[4].toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {cities &&
          cities.map((b) => (
            <Select.Option key={b.name} value={b._id}>
              {b.pnr} - {b.from.name} - {b.to.name} - {moment(b.from_date).format("DD.MM.YYYY")}
            </Select.Option>
          ))}
      </Select>
    );
};
