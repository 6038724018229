import React, { useEffect, useState, useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import { Row,Col,Form, Button,Card, message, Switch,Alert} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { ImageUrl, VideoUrl, LangPicker} from '../components';
import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback } from "react";
import { ChromePicker } from 'react-color';
import rgbHex from 'rgb-hex';


const ARVideoDetail = (props) => {
  let params = useParams()
  let history = useHistory()
  let id=params.id !== "add" ? params.id : false;

  let [modules] = useGlobal("modules");
  let [langCode] = useGlobal("langCode");
  let [langCms] = useGlobal("langCms");
  let [languagesCms] = useGlobal("languagesCms"); 
  let path = props.location.pathname.split('/')[1];
  let module
  if(modules !== null){
     [module] = modules.filter(el => el._id === path);
  }
  
  let newRecord = {
    lang: langCode ? langCode : langCms? langCms[0].code : "",
    url:'',
    logo:'',
    active: true,
    isArVideo:true,
    chromakey: '',
  }

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(true);

  let [validationCheck, setValidationCheck] = useState(false)

    useEffect(() => {
      if (modules) {
          setLoading(true)
          get();
      }
  }, [])

  let get = async () => {
    if(id) {
      await api.get("/rest/arvideo/" + id).then(({ data: { result, result_message } }) => {
        setData(result);   
        setLoading(false)
      });
    } else {

      setLoading(false)
    }
  }

  let validate = useCallback(() => {
    let errors = {};

    if (data.url == null)
      errors.url = languagesCms.ENFORCED

    if (data.logo == null)
      errors.logo = languagesCms.ENFORCED
   
   
    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);
  
  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else{
      if(id) {
        api.put("/rest/arvideo/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/ARVideo')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        if (typeof data.thumb === "object") {
          data.thumb = data.thumb.thumb
        }
        api.post("/rest/arvideo", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/ARVideo')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }  
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to={"/ARVideo"}>
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
            <Form layout="horizontal" size={"large"} onFinish={save}>

            <Row direction="row">
              <Col span={12}>
                <Form.Item label="Video" required help={errors.url} validateStatus={errors.url ? 'error' : 'success'}>
                  <VideoUrl record={data} languagesCms={languagesCms } setRecord={setData} name="url" thumb="thumb" url="url" />
                  <Alert  message={languagesCms.WARNING_FILE_SIZE} banner />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={"Logo"} help={errors.logo} validateStatus={errors.logo ? 'error' : 'success'}>
                  <ImageUrl record={data} setRecord={setData} name="logo" />
                  <Alert  message={"Görselin çözünürlüğü en az 300 x 300 piksel olmalıdır.PNG veya JPEG  formatında olmalıdır."} banner />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <Form.Item label={"AR Video"} help={errors.isArVideo} validateStatus={errors.isArVideo ? 'error' : 'success'}>
                  <Switch checked={data.isArVideo ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, isArVideo: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <Form.Item label={languagesCms.STATUS} help={errors.active} validateStatus={errors.active ? 'error' : 'success'}>
                  <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, active: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.LANG}>
                  <LangPicker langCms={langCms} langCode={langCode} languagesCms={languagesCms} record={data} setRecord={setData} name="lang" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.BACKGROUND_COLOR}
                  help={errors.chromakey}
                  validateStatus={errors.chromakey ? 'error' : 'success'}
                >
                  <ChromePicker
                    disableAlpha
                    color={data.chromakey}
                    onChange={(color) =>
                      setData({
                        ...data,
                        chromakey:
                          '#' + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b),
                      })
                    }
                  />
                </Form.Item>
              </Col>

            </Row>

            <Row direction="row">
              <Col span={24}>
              <Form.Item>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
              </Form.Item>
              </Col>
            </Row>
           
            </Form>
          </Card>
      </div>
    </div>
  );
};

export default ARVideoDetail;
