import React, { useState, useEffect, useMemo } from 'react';
import {
  Input,
  Form,
  Button,
  List,
  Switch,
  Typography,
  Alert,
} from 'antd';
import api from '../service/index';
import { nanoid } from 'nanoid';
export default (props) => {
  let { record, setRecord, name, disabled } = props;
  let tables = record[name];
  let [page, setPage] = useState(0);
  let languagesCms = props.languagesCms;
  let addQuestion = () => {
    setRecord({
      ...record,
      tables: [
        ...(tables ? tables : []),
        {
          tableNo: '',
          quota:0,
          fullness: 0,
        },
      ],
    });
    setPage(tables ? tables.length : 0);
  };


  let setField = (field) => (value) => {
    console.log("field    value",field)
    console.log("value    value",value)
    setRecord({
      ...record,
      [field]: value,
    });
  };
  let setTableField = (i) => (field) => (value) => {
    setField('tables')(
      tables.map((c, _i) =>
        i === _i ? { ...c, [field]: value } : c,
      ),
    );
  };


  let addTable = () => {
    console.log("clickedd    addTable",tables)
      setField('tables')([
        ...tables,
        {
          tableNo: '',
          quota:0,
          fullness: 0,
        },
      ]);
    
  };

  let deleteTable = (i) => {
    setField('tables')(tables.filter((c, _i) => _i !== i));
  };

  return (
    <div
      style={{
        border: '1px solid #ccc',
        borderRadius: 5,
        width: 1080,
        marginLeft:10,
        padding: 10,
      }}
    >



        <>
          <div style={{ borderTop: '1px solid #eee', paddingTop: 10 }}>
            <Typography.Text strong>{"Masa Reervasyon Bilgilerini Griniz."}</Typography.Text>
            <Button
              style={{ float: 'right' }}
              onClick={addTable}
              disabled={disabled}
            >
              {"Ekle"}
            </Button>
          </div>
          <List>
            {tables && tables.length>0 && tables.map((c, i) => (
              <List.Item
                key={i}
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <div style={{ marginRight: 5 }}>{i + 1}.</div>

                  <div>
                    <label>Masa No</label>
                    <Input
                    //   style={{
                    //     width: i === tables.length - 1 ? 450 : 610,
                    //   }}
                      //disabled={disabled}
                      value={c.tableNo}
                      onChange={(e) =>
                        setTableField(i)('tableNo')(e.target.value)
                      }
                    />
                  </div>
                  <div>
                  <label>Kota</label>
                    <Input
                    type='number'
                    //   style={{
                    //     width: i === tables.length - 1 ? 450 : 610,
                    //   }}
                      //disabled={disabled}
                      value={c.quota}
                      onChange={(e) =>
                        setTableField(i)('quota')(e.target.value)
                      }
                    />
                  </div>
                  <div>
                  <label>Doluluk</label>
                    <Input
                    type='number'
                    readOnly
                    //   style={{
                    //     width: i === tables.length - 1 ? 450 : 610,
                    //   }}
                      //disabled={disabled}
                      value={c.fullness}
                      onChange={(e) =>
                        setTableField(i)('fullness')(e.target.value)
                      }
                    />
                  </div>
   
                <div style={{ marginLeft: 10 }}>
                  <Button
                    type="danger"
                    shape="circle-outline"
                    disabled={disabled}
                    onClick={() => deleteTable(i)}
                  >
                    {languagesCms.DELETE}
                  </Button>
                </div>
              </List.Item>
            ))}
          </List>
        </>
      
    </div>
  );
};