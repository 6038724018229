import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import { Modal, Form, Row, message, Button, Upload, Progress } from 'antd';
import { FolderAddOutlined, UploadOutlined } from '@ant-design/icons';
import api from '../../service';

const UploadBuldImages = ({ isSmall }) => {
  const [languagesCms] = useGlobal('languagesCms');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  let [uploadStart, setUploadStart] = useState(false);

  const [resData, setResData] = useState({});

  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append('zipFile', file.originFileObj);
      });

      // Axios ile API'ye POST isteği gönderme
      // const response = await axios.post('your-api-endpoint', formData);

      message.loading({ content: 'Yükleniyor...' });
      api
        .post('/api/upload-zip-file', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setProgress(percent - 1);
          },
        })
        .then(({ data }) => {
          // set_Download(result[0].url ? result[0].url : result[0].thumb);
          setTimeout(() => setProgress(100), 1000);
          setTimeout(() => setProgress(0), 2000);
          if (
            data &&
            data.result_message &&
            data.result_message.type === 'success'
          ) {
            // let restFiles = files;
            // if (pop) restFiles = [result[0]].concat(restFiles);
            // else restFiles.push(result[0]);
            // setRecord({ ...record, [name]: restFiles });
            // setListFiles(convertToUploadFiles(restFiles));
            message.success('Yükleme Tamamlandı');
          } else {
            message.error('Yükleme Başarısız');
          }
          setUploadStart(false);
        });

      // İşlem başarılıysa
      // message.success("Dosya başarıyla yüklendi ve API'ye gönderildi.");
    } catch (error) {
      console.error('Hata:', error.message);
      message.error("Dosya yükleme ve API'ye gönderme işlemi başarısız oldu.");
    }
  };

  const onChange = (info) => {
    setFileList(info.fileList);
  };

  const beforeUpload = (file) => {
    // Dosya uzantısını kontrol etme
    const isZip = file.type === 'application/zip';
    if (!isZip) {
      message.error('Lütfen bir ZIP dosyası yükleyin!');
    }
    return isZip;
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setLoading(false);
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setLoading(false);
    setIsModalOpen(false);
  };

  const customRequest = ({ file, onSuccess, onError }) => {
    // Özel yükleme işlemleri, bu örnekte gerekli değil
    // Dosya yüklemesi işlemleri antd tarafından otomatik olarak gerçekleştirilecek
    onSuccess();

    console.log('file customRequest ', file);
  };

  return (
    <>
      <Button
        onClick={showModal}
        type="light"
        icon={<FolderAddOutlined />}
        style={{ marginRight: '5px' }}
        size="large"
      >
        {!isSmall && languagesCms.UPLOAD_BULK_IMAGES}
      </Button>

      <Modal
        title={languagesCms.UPLOAD_BULK_IMAGES}
        visible={isModalOpen}
        onOk={handleCancel}
        onCancel={handleOk}
        style={{ borderRadius: '21px !important' }}
        okText={languagesCms.OK_TEXT}
        cancelText={languagesCms.CANCEL_TEXT}
        width={500}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row direction="row">
            <Form.Item
              style={{ width: '100%' }}
              wrapperCol={{ span: 24, offset: 8 }}
              label="Zip Dosyası Yükleyiniz"
            >
              <Upload
                fileList={fileList}
                onChange={onChange}
                maxCount={1}
                beforeUpload={beforeUpload}
                customRequest={customRequest}
              >
                <Button icon={<UploadOutlined />}>Dosya Seç</Button>
              </Upload>
            </Form.Item>
          </Row>
          <Row direction="row">
            <p style={{ margin: '5px 0' }}>
              Zip içerisinde PNG ve JPG dosyaları bulundurunuz.
            </p>
            <p style={{ margin: '5px 0' }}>
              Zip içerisinde sadece resimler bulunmalıdır. Harici bir klasör
              olmamalıdır. Aksi takdirde resimler yüklenmeyecektir.
            </p>
            <p style={{ margin: '5px 0' }}>
              En Fazla 35 MB dosya yükleyebilirsiniz.
            </p>
          </Row>
          <Row direction="row">
            {progress > 0 ? (
              <Progress style={{ width: '100%' }} percent={progress} />
            ) : null}
          </Row>
          <Row direction="row">
            <Form.Item style={{ width: '100%' }} wrapperCol={{ span: 24 }}>
              <Button type="primary" danger block htmlType="submit">
                {' '}
                {languagesCms.SAVE}{' '}
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default React.memo(UploadBuldImages);
