import React, { useState, useMemo } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, tableNo, mode,reservationId } = props;
  let [tables, setTables] = useState([]);
  let _params = { sort: '-createdAt', page: 1, pageSize: 100 };
  let languagesCms = props.languagesCms;

  useMemo(() => {
    api.get("/rest/eventTableReservation/" + reservationId).then(({ data }) => {
        console.log("DATA eventTableReservation    ",data.result.tables)
      if (data && data.result && data.result.tables) {
        let dbTables = data.result.tables.sort((a, b) =>
          a.tableNo.localeCompare(b.tableNo),
        );
        setTables(dbTables);
      }
    });
  }, []);

  if (props.getCityById) {
    return (
      <Select
        showSearch={true}
        mode={mode}
        placeholder={languagesCms.SELECT_CITY}
        onChange={(v) => setRecord({ ...record, [tableNo]: v })}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {tables &&
          tables.map((b) => (
            <Select.Option key={b.tableNo} value={b.id}>
              {b.tableNo}
            </Select.Option>
          ))}
      </Select>
    );
  } else {
    return (
      <Select
        showSearch={true}
        mode={mode}
        placeholder={"Masa Seçiniz"}
        onChange={(v) => setRecord({ ...record, tableId: v })}
        filterOption={true}
      >
        {tables &&
          tables.map((b) => (
            <Select.Option key={b.tableNo} value={b.id}>
              {b.tableNo}
            </Select.Option>
          ))}
      </Select>
    );
  }
};
