import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name,lang } = props;
  console.log("LANG   ",lang)
  let [moduleCategories, setModuleCategories] = useState([]);
  let _params = {  page: 1, pageSize: 1000 };
  let languagesCms = props.languagesCms;
  useEffect(() => {
    api
      .get(`/rest/moduleCategories?${qs.stringify(_params)}`)
      .then(({ data }) => {
        if (data && data.result && data.result.rows.length > 0) {
          let dbModuleCategories = data.result.rows.map((a, b) =>{

            a.showName=a.langs.find(z=>z.lang==lang).title
            return a;
            }
          );
          setModuleCategories(dbModuleCategories);
        }
      });
  }, []);
console.log("moduleCategories  ",moduleCategories)
  return (
    <Select
      value={record[name] || []}
      placeholder={"Modül Kategorisi"}
      onChange={(v) => setRecord({ ...record, [name]: v })}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      <Select.Option key="" value="">
        Kategori Yok
      </Select.Option>
      {moduleCategories &&
        moduleCategories.map((b) => (
          <Select.Option key={b._id} value={b._id}>
            {b.showName}
          </Select.Option>
        ))}
    </Select>
  );
};

