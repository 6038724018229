import React, { useState, useEffect,useMemo } from 'react';
import { Select, Input, Button, Form } from 'antd';
import api from '../service/index';
import qs from 'qs';
import { PlusOutlined, LineOutlined } from '@ant-design/icons';
import { useCallback, useRef } from "react";
import ReactQuill  from 'react-quill';

export default (props) => {
  let { record, setRecord } = props;
  const quillRef = useRef(null);
  let [companyLangs, setCompanyLangs] = useState([]);

  let languagesCms = props.languagesCms;
  let langCode = props.langCode;
  let setLang = (name, value, index) => {
    setRecord({
      ...record,
      langs: record.langs.map((l, i) => {
        l.lang = i === index && name === 'lang' ? value : l.lang;
        l.name = i === index && name === 'name' ? value : l.name;
        l.content=i===index && name==='content' ? value:l.content;
        return { ...l };
      }),
    });
  };

  useEffect(() => {
    setCompanyLangs( [       {
      "_id" : "TR",
      "code" : "TR",
      "name" : "Türkçe",
      "icon" : "https://cdn.iciletisim.app/iciletisim-dev/setting/turkey.png",
      "textColor" : "#FFFFFF"
  }, 
  {
      "_id" : "EN",
      "code" : "EN",
      "name" : "English",
      "icon" : "https://cdn.iciletisim.app/iciletisim-dev/setting/united-states.png",
      "textColor" : "#FFFFFF"
  }]);
    record.langs[0].lang = record.langs[0].lang || langCode;
    api.get(`/rest/settings?${qs.stringify()}`).then(({ data }) => {
      if (data && data.result && data.result.rows.length > 0) {
        data.result.rows.forEach((element) => {
          if (element._id === 'supportedLangs') {
            setCompanyLangs( [       {
              "_id" : "TR",
              "code" : "TR",
              "name" : "Türkçe",
              "icon" : "https://cdn.iciletisim.app/iciletisim-dev/setting/turkey.png",
              "textColor" : "#FFFFFF"
          }, 
          {
              "_id" : "EN",
              "code" : "EN",
              "name" : "English",
              "icon" : "https://cdn.iciletisim.app/iciletisim-dev/setting/united-states.png",
              "textColor" : "#FFFFFF"
          }]);
          }
        });
      }
    });
  }, []);
  let imageHandler = () => {
    const input = document.createElement('input');
  
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
  
    input.onchange = async () => {
        const quill = quillRef.current.getEditor();
        const file = input.files[0];
        const formData = new FormData();
  
        formData.append('files_0', file);
  
        const range = quill.getSelection(true);
  
        quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loading.gif`);
        quill.setSelection(range.index + 1);
     
        let url = await api.post("/api/upload", formData, { headers: { 'ContenType': 'multipart/form-data'} }).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            return result[0].url;
          }
        });
        quill.deleteText(range.index, 1);
        quill.insertEmbed(range.index, 'image', url);
      };
    }
  
    const formats = ['header','font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link','image', 'color', 'size', 'video', 'align', 'background', 'direction', 'code-block', 'code','script','clean']
      const quillmodules = useMemo(() => ({
          toolbar: {
              container: [
                  [{header: '1'}, {header: '2'}, {header: [3, 4, 5, 6]}],
                  [{size: []}],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  ['link', 'image', 'video'],
                  [
                      { list: 'ordered' },
                      { list: 'bullet' },
                      { align: [] },
                      { direction: 'rtl' },
                    ],
                  ['clean'],
                  ['code-block'],
                  [{'indent': '-1'}, {'indent': '+1'}],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ script: 'sub' }, { script: 'super' }, 'formula']
              ],
              handlers: {
                  image: imageHandler
              }
          }
      }), [])
console.log("companyLangs  ",companyLangs)
console.log("record.langs  ",record)
  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        {companyLangs && (companyLangs.length > record.langs.length) && (
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            onClick={() => {
              setRecord({
                ...record,
                langs: [...record.langs, { lang: '', name: '', content:'' }],
              });
            }}
          >
            {languagesCms.ADD_LANG}
          </Button>
        )}
      </div>
      <div>
        {record.langs.length > 0 &&
          record.langs.map((l, i) => (
            <div key={i} style={{ display: 'flex' }}>
              <div style={{ marginTop: i > 0 ? 20 : 0 }}>
                <Form.Item label={languagesCms.LANG}>
                  <Select
                    style={{ width: 400, marginRight: 5 }}
                    value={l.lang || []}
                    mode="single"
                    placeholder={languagesCms.SELECT_LANGUAGE}
                    onChange={(v) => setLang('lang', v, i)}
                  >
                    {companyLangs &&
                      companyLangs.map((b) => (
                        <Select.Option
                          disabled={
                            record.langs.find((l) => l.lang === b._id)
                              ? true
                              : false
                          }
                          key={b._id}
                          value={b._id}
                        >
                          {b.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item label={`${l.lang || ''} ${languagesCms.TITLE}`}>
                  <Input
                    placeholder={languagesCms.TITLE}
                    name={l.name}
                    value={l.name}
                    onChange={(e) => setLang('name', e.target.value, i)}
                  />
                </Form.Item>
 
                <Form.Item label={languagesCms.CONTENTS} required>
                  <ReactQuill
                    ref={quillRef}
                    modules={quillmodules}
                    formats={formats}
                    name={l.content} theme="snow" value={l.content} 
                    onChange={(e) => setLang('content', e, i)} />
                </Form.Item>
              </div>

              {record.langs.length > 1 && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 20,
                  }}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<LineOutlined />}
                    onClick={() => {
                      setRecord({
                        ...record,
                        langs: record.langs.filter((r, ir) => i !== ir),
                      });
                    }}
                  />
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
