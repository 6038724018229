import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Card, message, Select, DatePicker, Switch } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { MapInput, CoordinateTypesPicker, AttendeePicker, DbSelectAttendee, DbSelectPlan, GroupPicker } from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import moment from 'moment';


const { Option } = Select;

const FlightDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let newRecord = {
    attendee: '',
    departure: '',
    arrival: '',
    arrivalService: '',
    departureService: '',
  };


  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);

  let [modules] = useGlobal('modules');
  let [languagesCms] = useGlobal('languagesCms');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }


  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/flight/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};
    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        console.log("data", data);
        api
          // .put('/rest/flight/' + id, data)
          .post('/rest/flight/submit', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/flight');
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        api
          .post('/rest/flight', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/flight');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  console.log("DATA Fligth add   ", data)
  let flightDepartureServices = (v) => {
    console.log("V   ", v)
    if (v) {
      let departureService = data.departure.services.find(z => z._id == v)
      if (departureService) {
        setData({ ...data, departureService: departureService });
      }
    }
  };
  let flightArrivalServices = (v) => {
    console.log("V   ", v)
    if (v) {
      let arrivalService = data.arrival.services.find(z => z._id == v)
      if (arrivalService) {
        setData({ ...data, arrivalService: arrivalService });
      }
    }
  };
  console.log("DATA  last   ", data)
  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/flight">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label='Kullanıcı'
                  required
                  help={errors.type}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <DbSelectAttendee url='attendees' placeholder="Kullanıcı" languagesCms={languagesCms} record={data} setRecord={setData} name="attendee" mode="single" />
                </Form.Item>

              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.GROUP}>
                  <GroupPicker languagesCms={languagesCms} record={data} setRecord={setData} name="groups" />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label='Uçuş Türü'
                  required
                  help={errors.type}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <Switch checkedChildren={"Uçak İle"} unCheckedChildren={"Kendi Aracım İle"}
                    initialChecked={data.departureType === "AIRPORT" ? "AIRPORT" : "CAR"}
                    record={data} setRecord={setData} name="airport"
                    checked={data.departureType === "AIRPORT" ? true : false}
                    onChange={v => setData({ ...data, departureType: v ? "AIRPORT" : "CAR", arrivalType: v ? "AIRPORT" : "CAR" })} />
                </Form.Item>
              </Col>
            </Row>

            {data.departureType === "AIRPORT" && (
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label='Gidiş'
                    required
                    help={errors.departure}
                    validateStatus={errors.departure ? 'error' : 'success'}
                  >
                    <DbSelectPlan url='flightPlan' filterName='type' filterValue='DEPARTURE' placeholder="Plan Seçin" languagesCms={languagesCms} record={data} setRecord={setData} name="departure" mode="single" />
                  </Form.Item>

                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label='Dönüş'
                    required
                    help={errors.arrival}
                    validateStatus={errors.arrival ? 'error' : 'success'}
                  >
                    <DbSelectPlan url='flightPlan' filterName='type' filterValue='ARRIVAL' placeholder="Plan Seçin" languagesCms={languagesCms} record={data} setRecord={setData} name="arrival" mode="single" />
                  </Form.Item>
                </Col>

              </Row>

            )}
            {data.departureType == "AIRPORT" && (
              <Row direction="row">

                {data.departure && data.departure.services && data.departure.services.length > 0 &&
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label='Gidiş Servisi'
                  >
                    <Select
                      defaultValue={data.departureService ? data.departureService.location : "Servis Kullanmayacağım"}
                      placeholder={"Gidiş Servis Seç"}
                      showSearch={true}
                      onChange={flightDepartureServices}
                      filterOption={(input, option) =>
                        option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >=
                        0 ||
                        option.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {data.departure.services && data.departure.services.length > 0 &&
                        data.departure.services.map((b) => (
                          <Select.Option key={b._id} value={b._id}>
                            {b.location} - {b.description}
                          </Select.Option>
                        ))}
                    </Select>
                    </Form.Item>

                  </Col>

                }
                {data.arrival && data.arrival.services && data.arrival.services.length > 0 &&
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label='Dönüş Servisi'
                  >
                    <Select
                      defaultValue={data.arrivalService ? data.arrivalService.location : "Servis Kullanmayacağım"}
                      placeholder={"Dönüş Servis Seç"}
                      showSearch={true}
                      onChange={flightArrivalServices}
                      filterOption={(input, option) =>
                        option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >=
                        0 ||
                        option.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {data.arrival.services && data.arrival.services.length > 0 &&
                        data.arrival.services.map((b) => (
                          <Select.Option key={b._id} value={b._id}>
                            {b.location} - {b.description}
                          </Select.Option>
                        ))}
                    </Select>
                    </Form.Item>
                  </Col>
                }
              </Row>

            )
            }

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default FlightDetail;
